import React, {useEffect, useState} from 'react';

import { PageHeader, Button, Tabs, Loading } from '@labqube/components';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from '@labqube/react-draft-wysiwyg';
import {getTestCase} from "../../../services/test-cases";
import {getTestCaseSteps} from "../../../services/test-case-steps";
import Name from "../name";
import Steps from "../steps";
import { Link, useParams } from 'react-router-dom';

import '@labqube/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Viewer = () => {
    const [testCase, setTestCase] = useState({});
    const [testCaseSteps, setTestCaseSteps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const { id:testCaseId } = useParams();

    useEffect(() => {
        Promise.all([
            getTestCase(testCaseId),
            getTestCaseSteps(testCaseId),
        ]).then(([testCase, testCaseSteps]) => {
           setTestCase(testCase);
           setTestCaseSteps(testCaseSteps);
           setLoading(false);
            if (testCase.preconditions) {
                const rawContent = JSON.parse(testCase.preconditions); // Asegúrate de que `preconditions` sea un JSON válido
                const contentState = convertFromRaw(rawContent);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }).catch(e => {
            console.log(e);
            // TODO https://labqube.atlassian.net/browse/COM-4
            setLoading(false);
        })
    }, [testCaseId]);

    if (loading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test case...
                </p>
            </Loading>
        )
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'View test case'}
                breadcrumbs={[{
                    href: <Link to={'/test-cases'}>Test cases</Link>,
                }, {
                    href: ``,
                    text: 'View test case'
                }]}
                action={
                    <Link to={`/test-cases/${testCaseId}/edit`}>
                        <Button type={'primary'} style={{height: 35}}>
                            Edit test case
                        </Button>
                    </Link>
                }
            />

            <Name name={testCase.name} description={testCase.description} />
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: '60%', margin: 'auto' }}>
                    <p>Precondition</p>
                    <Editor
                        editorState={editorState}
                        readOnly={true}
                        toolbarHidden={true}
                        wrapperClassName='demo-wrapper'
                        editorClassName='demo-edit'
                    />
                </div>
            </div>
            <br/>
            <br/>
            <Tabs style={{ width: '60%', marginLeft: '20%'}} items={[
                {
                    key: 'steps',
                    title: 'Steps',
                    content: <Steps steps={testCaseSteps} />,
                }
            ]} />
        </div>
    )
};

export default Viewer;
