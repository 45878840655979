import React, { useContext } from 'react';
import styled from 'styled-components';

import TestExecutionContext from '../../../context/test-execution';

import './index.css';

const icons = {
    'pending': 'fa-solid fa-hourglass-start',
    'success': 'fa-solid fa-check',
    'failure': 'fa-solid fa-xmark',
    'skip': 'fa-solid fa-forward',
};

const TestCase = ({ testCase: testCaseRun }) => {
    const { selectedTestCaseRun, selectTestCaseRun } = useContext(TestExecutionContext);

    const Wrapper = styled.div`
        cursor: pointer;
        font-weight: ${selectedTestCaseRun?._id === testCaseRun._id ? 'bold' : 'normal'};
        padding: 5px;
        background-color: ${selectedTestCaseRun?._id === testCaseRun._id ? '#CCC' : 'white'};
    `;

    const Content = styled.div`
        display: flex;
        align-items: baseline;
    `;

    const StatusBadge = styled.i`
        margin-right: 5px;
    `;

    const TestCaseName = styled.span`
        word-break: break-word;
    `;

    return (
        <Wrapper onClick={() => selectTestCaseRun(testCaseRun._id)}>
            <Content>
                <span className={`result-${testCaseRun.status}`}>
                    <StatusBadge className={icons[testCaseRun.status]} />
                </span>
                <TestCaseName>{ testCaseRun.test_case_id_details.name }</TestCaseName>
            </Content>
        </Wrapper>
    );
}

export default TestCase;
