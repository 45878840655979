import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';

import { PageHeader, Button, Description, Table, Loading } from '@labqube/components';
import {getTestPlan} from "../../services/test-plans";

import './test-plan.css';

const TestPlan = () => {
    const [testPlan, setTestPlan] = useState({});
    const [isLoading, setLoading] = useState(true);

    const { id:testPlanId } = useParams();

    useEffect(() => {
        getTestPlan({ id: testPlanId }).then(testPlan => {
            setTestPlan(testPlan);
            setLoading(false);
        })
    }, [testPlanId]);

    if (isLoading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test plan...
                </p>
            </Loading>
        )
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'View Test Plan'}
                breadcrumbs={[
                    {
                        href: <Link to={'/test-plans'}>Test plans</Link>,
                    },
                    {
                        href: '',
                        text: 'View test plan'
                    }
                ]}
                action={
                    <Link to={`/test-plans/${testPlanId}/edit`}>
                        <Button type={'primary'} style={{height: 35}}>
                            Edit test plan
                        </Button>
                    </Link>
                }
            />

            <div id="wizard-stage-name">
                <Description title={'Test plan name'} content={<span style={{ wordBreak: 'break-word' }}>{ testPlan.name }</span>} />
                <Description title={'Description'} content={<span style={{ wordBreak: 'break-word' }}>{ testPlan.description || '-' }</span>} />

                <Description
                    title="Associated test cases"
                    style={{
                        marginTop: 25,
                        marginBottom: 0,
                    }}
                />
                <Table
                    headers={['Name', 'Description']}
                    columnsWidth={['30%', '60%']}
                    items={testPlan.testCases.map(testCase => {
                        return {
                            id: testCase.test_case_id_details._id,
                            content: {
                                name: (
                                    <span style={{ color: 'black', wordBreak: 'break-word' }}>
                                        {testCase.test_case_id_details.name}
                                    </span>
                                ),
                                description: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ testCase.test_case_id_details.description || '-' }</span>,
                            }
                        }
                    })}
                />
            </div>
        </div>
    );
}

export default TestPlan;
