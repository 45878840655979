import React, { useContext } from 'react';
import { STATUS } from '../../../constants';
import TestExecution from '../../../context/test-execution';
import TestCase from './test-case';

const TestCasesList = () => {
    const { testExecution } = useContext(TestExecution);

    function Title() {
        if (testExecution?.status === STATUS.TEST_EXECUTION_RUNNING) {
            return (
                <p style={{ marginBottom: 50 }}>Click on a test case from the list below to evaluate it</p>
            )
        }

        return <></>
    }

    return (
        <>
            <Title />
            {
                testExecution?.testCases?.map(testCase => {
                    return <TestCase key={testCase._id} testCase={testCase} />
                })
            }
        </>
    )
}

export default TestCasesList;
