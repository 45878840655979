import React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  defaultContext,
} from "@tanstack/react-query";

import "./App.css";

import {
  NavigationProvider,
  SideNavigation,
  NavigationItem,
  CollapsibleSection,
} from "./components/SideNavigation/SideNavigation";

import TestExecutionProvider from "./context/test-execution/provider";
import TestExecutionContext from "./context/test-execution";

import TestCasesList from "./sections/test-cases/list";
import TestCasesViewer from "./sections/test-cases/viewer";
import TestCasesWizard from "./sections/test-cases/wizard";

import TestPlansList from './sections/test-plans/list';
import TestPlansForm from './sections/test-plans/form';
import TestPlan from "./sections/test-plans/test-plan";

import TestCyclesList from './sections/test-cycles/list';
import TestCyclesForm from './sections/test-cycles/form';
import TestCycle from "./sections/test-cycles/test-cycle";
import TestExecution from "./sections/test-cycles/test-execution";

const queryClient = new QueryClient({
  context: defaultContext,
});

function App() {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div className="app-container">
      <HashRouter>
        <NavigationProvider>
          <SideNavigation
            label="Main Navigation"
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          >
            <CollapsibleSection heading="Design" defaultOpen>
              <NavigationItem to="/test-cases">Test Cases</NavigationItem>
              <NavigationItem to="/test-plans">Test Plans</NavigationItem>
            </CollapsibleSection>
            <CollapsibleSection heading="Execution" defaultOpen>
              <NavigationItem to="/test-cycles">Test Cycles</NavigationItem>
            </CollapsibleSection>
          </SideNavigation>
        </NavigationProvider>
        <div className={`content ${isCollapsed ? 'content-expanded' : ''}`}>
          <QueryClientProvider client={queryClient} context={defaultContext}>
            <Routes>
              <Route path="/test-cases" element={<TestCasesList />} />
              <Route path="/test-cases/new" element={<TestCasesWizard />} />
              <Route path="/test-cases/:id" element={<TestCasesViewer />} />
              <Route path="/test-cases/:id/edit" element={<TestCasesWizard />} />

              <Route path="/test-plans" element={<TestPlansList />} />
              <Route path="/test-plans/new" element={<TestPlansForm />} />
              <Route path="/test-plans/:id" element={<TestPlan />} />
              <Route path="/test-plans/:id/edit" element={<TestPlansForm />} />

              <Route path="/test-cycles" element={<TestCyclesList />} />
              <Route path="/test-cycles/new" element={<TestCyclesForm />} />
              <Route path="/test-cycles/:id" element={<TestCycle />} />
              <Route path="/test-cycles/:id/edit" element={<TestCyclesForm />} />
              <Route 
                path="/test-cycles/:testCycleId/test-executions/:id"
                element={
                  <TestExecutionProvider>
                    <TestExecutionContext.Consumer>
                    {
                      () => <TestExecution />
                    }
                    </TestExecutionContext.Consumer>
                  </TestExecutionProvider>
                }
              />

              <Route path="*" element={<Navigate to="/test-cases" replace />} />
            </Routes>
          </QueryClientProvider>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
