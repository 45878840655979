import React from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from '@labqube/react-draft-wysiwyg';
import '@labqube/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';

const Steps = ({ steps }) => {
    const renderEditorContent = (content) => {
        let editorState;
        try {
            const rawContent = JSON.parse(content);
            editorState = EditorState.createWithContent(convertFromRaw(rawContent));
        } catch (e) {
            console.error("Error parsing content:", e);
            editorState = EditorState.createEmpty();
        }
    
        return (
            <div className="editor-container">
                <Editor
                    editorState={editorState}
                    readOnly
                    toolbarHidden
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor-content"
                />
            </div>
        );
    };
    

    return (
        <div id="steps-container">
            {Object.keys(steps).map((key) => {
                const step = steps[key];
                return (
                    <div key={key} className="step-row">
                        <div className="step-column step-action">
                            <h4>Action</h4>
                            {renderEditorContent(step.action)}
                        </div>
                        <div className="step-column step-expected-result">
                            <h4>Expected Result</h4>
                            {renderEditorContent(step.expectation)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Steps;
