import React  from 'react';

import {Input, TextArea} from '@labqube/components';

import './index.css';

const Name = ({ name, description, setName, setDescription }) => {
    return (
        <div id="wizard-stage-name">
            <div>
                <p>Test case name <span className={'required'}>*</span></p>
                <Input width="100%" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
                <p>Description (optional)</p>
                <TextArea width="100%" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
        </div>
    )
}

export default Name;
