import React from 'react';
import { Table, Button } from '@labqube/components';
import { EditorState } from 'draft-js';
import { Editor } from '@labqube/react-draft-wysiwyg';

import './index.css';

const Steps = ({ steps, addStep, updateStep, deleteStep }) => {
    const handleEditorChange = (step, field) => (editorState) => {
        updateStep({
            ...step,
            [field]: editorState,
        });
    };

    const renderEditor = (step, field) => (
        <div style={{ width: '90%', marginTop: 10, marginBottom: 10 }}>
            <Editor
                editorState={step[field] || EditorState.createEmpty()}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={handleEditorChange(step, field)}
                toolbar={{
                    options: ['inline', 'list', 'textAlign', 'link', 'history', 'image'],
                    inline: { options: ['bold', 'italic', 'underline'] },
                    list: { options: ['unordered', 'ordered'] },
                    textAlign: { options: ['left', 'center', 'right', 'justify'] },
                    image: {
                        uploadCallback: (file) => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve({ data: { link: reader.result } });
                            reader.onerror = (error) => reject(error);
                        }),
                        previewImage: true,
                        alt: { present: true, mandatory: false },
                    },
                }}
            />
        </div>
    );

    const renderActions = (step) => (
        <i
            className="fa-solid fa-trash"
            style={{ cursor: 'pointer' }}
            onClick={() => deleteStep(step.id)}
        />
    );

    return (
        <div id="steps-container">{
            Object.keys(steps).length
            ? (
                <Table
                    headers={['Action', 'Expected Result', 'Actions']}
                    columnsWidth={['47%', '47%', '6%']}
                    items={Object.keys(steps).map((key) => {
                        const step = steps[key];
                        return {
                            id: key,
                            content: {
                                action: renderEditor(step, 'action'),
                                'expected result': renderEditor(step, 'expectation'),
                                actions: renderActions(step),
                            },
                        };
                    })}
                />
            ) : <p style={{ textAlign: 'center' }}>There are no steps registered yet</p>
        }
            <div style={{ textAlign: 'center' }}>
                <Button
                    style={{ marginTop: 30, marginBottom: 10 }}
                    type="primary"
                    onClick={() => addStep({
                        action: EditorState.createEmpty(),
                        expectation: EditorState.createEmpty(),
                    })}
                >
                    Add step
                </Button>
            </div>
        </div>
    );
};

export default Steps;
