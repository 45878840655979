(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("draft-js"), require("immutable"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["draft-js", "immutable", "react"], factory);
	else if(typeof exports === 'object')
		exports["reactDraftWysiwyg"] = factory(require("draft-js"), require("immutable"), require("react"));
	else
		root["reactDraftWysiwyg"] = factory(root["draft-js"], root["immutable"], root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__256__, __WEBPACK_EXTERNAL_MODULE__292__, __WEBPACK_EXTERNAL_MODULE__156__) => {
return 