// SideNavigation.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './SideNavigation.css';

export const NavigationProvider = ({ children }) => {
  return <div className="navigation-provider">{children}</div>;
};

export const SideNavigation = ({ label, children, isCollapsed, toggleCollapse }) => {
  const caretIconExpand = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="presentation"
      style={{ transform: 'rotate(270deg)'}}
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M8.292 10.293a1.01 1.01 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0"
      ></path>
    </svg>
  )
  const caretIconCollapse = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="presentation"
      style={{ transform: 'rotate(90deg)'}}
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M8.292 10.293a1.01 1.01 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0"
      ></path>
    </svg>
  )

  return (
    <div className={`side-navigation ${isCollapsed ? 'collapsed' : ''}`}>
      <div className='header' onClick={toggleCollapse}>
        {
          isCollapsed ? <><img alt='test-management-logo' src='/img/test-management.png' height={24} width='auto' style={{ marginRight: 5, cursor: 'pointer' }} /></> : <><img alt='test-management-logo' src='/img/test-management.png' height={24} width='auto' style={{ marginRight: 5 }} /><span className='collapsible-heading'>Test Management</span></>
        }
        <div className="toggle-button">
          {isCollapsed ? caretIconExpand : caretIconCollapse}
        </div>
      </div>
      {
        isCollapsed ? <></> : <nav aria-label={label}>{children}</nav>
      }
    </div>
  );
};

export const Section = ({ children }) => {
  return <div className="navigation-section">{children}</div>;
};

export const HeadingItem = ({ children }) => {
  return <div className="navigation-heading">{children}</div>;
};

export const NavigationItem = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);

  return (
    <Link
      to={to}
      className={`navigation-item ${isActive ? 'active' : ''}`}
    >
      {children}
    </Link>
  );
};

export const CollapsibleSection = ({ heading, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const caretIconExpand = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="presentation"
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M8.292 10.293a1.01 1.01 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0"
      ></path>
    </svg>
  )
  const caretIconCollapse = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      role="presentation"
      style={{ transform: 'rotate(180deg)'}}
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M8.292 10.293a1.01 1.01 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0"
      ></path>
    </svg>
  )

  return (
    <div className="collapsible-section">
      <div
        className="collapsible-heading"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {isOpen ? caretIconCollapse : caretIconExpand} {heading}
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};
