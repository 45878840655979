import axios from '../lib/axios';

export function getTestSuites() {
    return axios.get('/api/test-management/test-suites')
        .then(response => response.data);
}

export function createTestSuite(data) {
    return axios.post('api/test-management/test-suites', data)
        .then(response => response.data);
}