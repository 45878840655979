import React  from 'react';

import './index.css';
import { Description } from "@labqube/components";

const Name = ({ name, description, }) => {
    return (
        <div id="wizard-stage-name">
            <Description title={'Test case name'} content={name} style={{wordBreak: 'break-word'}} />
            <Description title={'Description'} content={description || '-'} style={{wordBreak: 'break-word'}} />
        </div>
    )
}

export default Name;
