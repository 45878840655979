import React, { useEffect } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { Button } from "@labqube/components";
import { useSpotlight } from "./SpotlightContext";

import './index.css';

const SpotlightWrapper = styled.div`
  position: absolute;
  background: rgb(159, 143, 239);
  color: black;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;
  max-width: 250px;
  min-width: 250px;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const SpotlightStep = ({
  children,
  target,
  title,
  order,
  position = "bottom",
  nextButton,
  previousButton,
  dismissable = false,
  offset = 10,
}) => {
  const { 
    currentStep, 
    registerStep, 
    steps, 
    updateStepPosition, 
    closeSpotlight,
    nextStep,
    prevStep,
    isOnboardingVisible,
  } = useSpotlight();

  useEffect(() => {
    if (!isOnboardingVisible) return;
    if (!target?.current && !(target instanceof HTMLElement)) return;

    const targetElement = target.current || target;

    registerStep({ title, position: null, order });

    const updatePosition = () => {
      const rect = targetElement.getBoundingClientRect();
      const position = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
        height: rect.height,
      };
      updateStepPosition(title, position);
    };

    updatePosition();

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(targetElement);

    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  // eslint-disable-next-line
  }, [isOnboardingVisible, target, title, order, updateStepPosition]);

  if (!isOnboardingVisible || steps[currentStep]?.title !== title) return null;

  const { position: targetPosition } = steps[currentStep] || {};

  const calculatePosition = () => {
    if (!targetPosition) return {};
    const { top, left, width, height } = targetPosition;

    switch (position) {
      case "top":
        return { top: top - offset, left: left + width / 2 };
      case "bottom":
        return { top: top + height + offset, left: left + width / 2 };
      case "left":
        return { top: top + height / 2, left: left - offset };
      case "right":
        return { top: top + height / 2, left: left + width + offset };
      case "top-left":
        return { top: top - offset, left: left - offset };
      case "top-right":
        return { top: top - offset, left: left + width + offset };
      case "bottom-left":
        return { top: top + height + offset, left: left - offset };
      case "bottom-right":
        return { top: top + height + offset, left: left + width + offset };
      default:
        return { top: top + height + offset, left: left + width / 2 };
    }
  };

  const spotlightStyle = calculatePosition();

  return createPortal(
    <>
      <Backdrop />
      <SpotlightWrapper
        style={{
          top: spotlightStyle.top,
          left: spotlightStyle.left,
          transform: "translate(-50%, -50%)",
        }}
      >
        {children}
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {dismissable && (
            <div style={{ marginTop: 10, textAlign: "right" }}>
              <Button
                type="subtle"
                onClick={closeSpotlight}
                style={{ padding: "5px 10px", cursor: "pointer" }}
              >
                Close
              </Button>
            </div>
          )}
          <div onClick={nextStep}>{nextButton}</div>
          <div onClick={prevStep}>{previousButton}</div>
        </div>
      </SpotlightWrapper>
    </>,
    document.body
  );
};

export default SpotlightStep;
