import React, { createContext, useContext, useEffect, useState } from "react";

const SpotlightContext = createContext();

export const SpotlightProvider = ({ children, localStorageKey = "spotlightOnboarding" }) => {
  const [currentStep, setCurrentStep] = useState(-1); // Inicializamos con -1 para evitar que se muestre de inmediato
  const [steps, setSteps] = useState([]);
  const [isOnboardingVisible, setIsOnboardingVisible] = useState(false);

  const registerStep = (step) => {
    setSteps((prevSteps) => {
      const stepExists = prevSteps.some((s) => s.title === step.title);
      return stepExists ? prevSteps : [...prevSteps, step];
    });
  };

  const updateStepPosition = (title, position) => {
    const existingStep = steps.find((step) => step.title === title);
    if (
      existingStep &&
      (existingStep.position?.top !== position.top ||
        existingStep.position?.left !== position.left ||
        existingStep.position?.width !== position.width ||
        existingStep.position?.height !== position.height)
    ) {
      setSteps(
        steps.map((step) =>
          step.title === title ? { ...step, position } : step
        )
      );
    }
  };

  // Navegación entre pasos
  const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  // Cerrar Spotlight y marcar como completado
  const closeSpotlight = () => {
    setCurrentStep(-1);
    setIsOnboardingVisible(false);
    localStorage.setItem(localStorageKey, "completed");
  };

  // Ordenar pasos según el orden definido
  const sortedSteps = steps.sort((a, b) => a.order - b.order);

  // Al cargar el componente, verificamos si el onboarding ya fue completado
  useEffect(() => {
    const onboardingStatus = localStorage.getItem(localStorageKey);
    if (onboardingStatus !== "completed") {
      setIsOnboardingVisible(true);
      setCurrentStep(0); // Iniciar en el primer paso si el onboarding no está completado
    }
  }, [localStorageKey]);

  return (
    <SpotlightContext.Provider
      value={{
        currentStep,
        steps: sortedSteps,
        registerStep,
        updateStepPosition,
        nextStep,
        prevStep,
        closeSpotlight,
        isOnboardingVisible, // Exponer si el onboarding está visible
      }}
    >
      {children}
    </SpotlightContext.Provider>
  );
};

export const useSpotlight = () => useContext(SpotlightContext);
