import axios from '../lib/axios';

export function getTestSections(testSuiteId) {
    return axios.get(`/api/test-management/test-suites/${testSuiteId}/test-sections`)
        .then(response => response.data);
}

export function createTestSection(data) {
    return axios.post('api/test-management/test-sections', data)
        .then(response => response.data);
}