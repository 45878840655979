import React, {useEffect, useState} from 'react';
import { to } from 'await-to-js';
import ReactTooltip from 'react-tooltip';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { PageHeader, Button, Input, TextArea, Table, Message } from '@labqube/components';

import './form.css';
import {createTestPlan, getTestPlan, updateTestPlan} from "../../services/test-plans";

const Form = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [testCases, setTestCases] = useState([]);
    const [originalTestCases, setOriginalTestCases] = useState([]);

    const navigate = useNavigate();
    const { id:testPlanId } = useParams();

    useEffect(() => {
        if (testPlanId) {
            getTestPlan({ id: testPlanId }).then(testPlan => {
               setName(testPlan.name);
               setDescription(testPlan.description);
               setOriginalTestCases(testPlan.testCases);
               setTestCases(testPlan.testCases.map(t => t.test_case_id_details));
            });
        }
    }, [testPlanId]);

    async function saveTestPlan() {
        const [err] = await to(updateTestPlan({
            id: testPlanId,
            name,
            description,
            testCases,
            originalTestCases,
        }));

        if (err) {
            // TODO show notification
        } else {
            navigate('/test-plans');
        }
    }

    async function createNewTestPlan() {
        const [err] = await to(createTestPlan({ name, description, testCases }));

        if (err) {
            // TODO show notification
        } else {
            navigate('/test-plans');
        }
    }

    function deleteTestCase(testCase) {
        setTestCases(testCases.filter(t => t._id !== testCase._id));
    }

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <ReactTooltip globalEventOff={'click'} />
            <PageHeader
                title={testPlanId ? 'Edit Test Plan' : 'New Test Plan'}
                style={{ scrollPaddingTop: 41, position: 'sticky', top: 0, backgroundColor: 'white' }}
                breadcrumbs={[
                    {
                        href: <Link to={'/'}>Test plans</Link>,
                    },
                    {
                        href: '',
                        text: testPlanId ? 'Edit test plan' : 'New test plan'
                    }
                ]}
                action={
                    <Button
                        data-tip={!name.trim() || testCases.length === 0 ? 'Please name your test plan and add test cases' : ''}
                        type={'primary'}
                        style={{height: 30}}
                        disabled={!name.trim() || testCases.length === 0}
                        onClick={() => testPlanId ? saveTestPlan() : createNewTestPlan()}
                    >
                        {testPlanId ? 'Save test plan' : 'Create test plan'}
                    </Button>
                }
            />

            <div id="test-plan-wrapper">
                <div>
                    <p>Test plan name <span className={'required'}>*</span></p>
                    <Input width="100%" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div>
                    <p>Description (optional)</p>
                    <TextArea width="100%" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>

                <Table
                    headers={['Name', 'Description', 'Actions']}
                    columnsWidth={['30%', '50%', '10%']}
                    items={testCases.map(testCase => {
                        return {
                            id: testCase._id,
                            content: {
                                name: <span style={{ color: 'black', wordBreak: 'break-word' }}>{ testCase.name }</span>,
                                description: testCase.description || '-',
                                actions: (
                                    <div>
                                        <i className="fa-solid fa-trash clickable" onClick={() => deleteTestCase(testCase)}></i>
                                    </div>
                                )
                            }
                        }
                    })}
                    style={{ paddingTop: 20 }}
                />

                <Message
                    title={testCases.length === 0 ? 'No test cases associated' : ''}
                    description={testCases.length === 0 ? 'Try associating a one!' : 'Associate more test cases'}
                    actionComponent={
                        <Button type={'primary'} onClick={() => {
                            //setModalVisible(true)
                            window.AP.dialog.create({
                                key: 'labqube-test-cases-dialog',
                                customData: {
                                    enableSelectionFromTestPlans: false
                                }
                              }).on("close", ({ type = 'cancel', testCases:selectedTestCases = [] } = {}) => {
                                if (type === 'submit') {
                                    setTestCases([...testCases, ...selectedTestCases]);
                                }
                              });
                        }}>
                            Add test cases
                        </Button>
                    }
                    style={{ marginTop: 0 }}
                />
            </div>
        </div>
    );
}

export default Form;
