import { useQuery } from '@tanstack/react-query'

import { getTestSuites } from '../services/test-suites';
import { getTestSections } from '../services/test-sections';

const useTestSuites = () => {

    const fetchTestSuites = () => {
        return getTestSuites()
            .then(testSuites => {
                return Promise.all(testSuites.map(suite => {
                    return getTestSections(suite._id)
                        .then(sections => {
                            return {
                                id: suite._id,
                                name: suite.name,
                                children: sections.map(section => ({
                                    id: section._id,
                                    name: section.name,
                                })),
                            };
                        })
                }))
            });
    };

    const { isLoading, error, data } = useQuery({
        queryKey: ['test-suites'],
        queryFn: fetchTestSuites,
        refetchOnWindowFocus: false,
    });

    return {
        isLoading: isLoading && !data,
        data: data || [],
        error,
    }
};

export default useTestSuites;
